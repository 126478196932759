/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router";

import { Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import _ from "lodash";
import { SUPPLIER_ROLES } from "src/constants/permissions";
import { formatMoneyUnlimitedDecimals } from "src/utility/utilityFunctions";

import {
  PaginatedResourceProps,
  SmartTable,
  insertIf,
  showFirst,
} from "@components/SmartTable";
import { variantId } from "@features/items";
import { displayDate } from "@features/reports";
import { PurchaseOrderVariant } from "@models/PurchaseOrderVariant";
import useRoleIs from "@utils/useRoleIs";

type PurchaseOrderHistoryTableProps = PaginatedResourceProps & {
  data: PurchaseOrderVariant[];
};

// overkill, but it's fun lol
function moneyCell(fn: (pov: PurchaseOrderVariant) => number) {
  return {
    align: "right" as const,
    render: (_, pov) => formatMoneyUnlimitedDecimals(fn(pov)),
  };
}
moneyCell.align = "right" as const;
moneyCell.render = (v: number) => formatMoneyUnlimitedDecimals(v);

const PurchaseOrderHistoryTable = ({
  data,
  ...props
}: PurchaseOrderHistoryTableProps) => {
  const roleIs = useRoleIs();
  const isSupplier = roleIs(SUPPLIER_ROLES);

  const actualCost = (pov: PurchaseOrderVariant) =>
    Number(isSupplier ? pov.actualCost : pov.galloCost);

  return (
    <SmartTable
      rows={data}
      {...props}
      columns={[
        {
          id: "purchaseOrder.id",
          label: "Purchase Order #",
          sort: "po-id",
          to: (row) => `/purchasing/purchase-orders/${row.purchaseOrder.id}`,
          render: (id, pov) => (
            <div tw="flex gap-2 items-center">
              {pov.isRush && (
                <Tooltip title="Order On Rush Status">
                  <Warning fontSize="small" />
                </Tooltip>
              )}
              <Link
                tw="font-medium underline tracking-wide"
                to={`/purchasing/purchase-orders/${id}`}
                onClick={(e) => e.stopPropagation()}
              >
                {id}
              </Link>
            </div>
          ),
        },
        { id: "poExternalSapId", label: "SAP PO #" },
        { id: "supplierReference", label: "Supplier Ref #" },
        ...insertIf(roleIs("super") || isSupplier, {
          id: "invoiceNumber",
          label: "Invoice #",
        }),
        {
          id: "_variantId",
          label: "Seq #",
          render: (_, pov) => (
            <span tw="whitespace-nowrap">{variantId(pov.variant)}</span>
          ),
        },
        ...insertIf(!isSupplier, {
          id: "supplierName",
          label: "Supplier",
          sort: "supplier-name",
        }),
        { id: "brandNames", label: "Brand", render: showFirst },
        { id: "itemExternalWorkfrontId", label: "Project #" },
        { id: "itemTypeDescription", label: "Item Type" },
        { id: "itemComment", label: "Item Description" },
        { id: "qty", label: "Qty" },
        ...insertIf(!isSupplier, {
          id: "itemEstimatedCost",
          label: "Est. Cost/Unit",
          ...moneyCell,
        }),
        {
          id: "_actCost",
          label: "Act. Cost/Unit",
          ...moneyCell(actualCost),
        },
        {
          id: "_totalCost",
          label: "Total cost",
          ...moneyCell((pov) => pov.qty * actualCost(pov)),
        },
        {
          id: "poStatus",
          label: "Status",
          sort: "po-status",
          render: (v, pov) =>
            isSupplier && pov.poStatus === "submitted" ? "New" : _.startCase(v),
        },
        {
          id: "poSubmittedAt",
          label: "Submitted Date",
          sort: "po-submitted-at",
          render: displayDate,
        },
        {
          id: "poInMarketDate",
          label: "In Market Date",
          sort: "po-in-market-date",
          render: (inMarketDate, pov) =>
            displayDate(
              isSupplier && !pov.poIsDirectShip
                ? pov.poDeliverToWarehouseDate
                : inMarketDate
            ),
        },
        ...insertIf(
          roleIs("super", "supplier"),
          { id: "poShipmentCount", label: "Shipment Count" },
          { id: "poKeyAccountTape", label: "Shipper Tape" }
        ),
        ...insertIf(roleIs("super", "select-purchaser"), {
          id: "poSentToFlowAt",
          label: "Sent to Flow Date",
          render: displayDate,
        }),
        { id: "poPurchaserName", label: "Purchased By" },
        {
          id: "poIsDirectShip",
          label: "Allocated",
          render: (v) => (v ? "Direct Ship" : "CDC"),
        },
      ]}
    />
  );
};

export default PurchaseOrderHistoryTable;
